// Sidebar dark

.app-sidebar {
    &--dark {
        .app-sidebar--header {
            img {
                -webkit-transition: all 0.8s ease-out;
                -moz-transition: all 0.8s ease-out;
                -o-transition: all 0.8s ease-out;
                transition: all 0.8s ease-out;
            }
            background: transparent;

            .collapse-sidebar-btn {
                background: transparent;
                color: rgba($white, 0.8);
                opacity: 1;

                &:hover {
                    background: rgba($black, 0.2);
                    color: $white;
                }
            }
            &:after {
                background: rgba($white, 0.15);
            }

            .nav-logo {
                a {
                    color: $white;

                    i {
                        background: $white;
                    }
                }
            }
        }

        .hamburger-inner,
        .hamburger-inner:after,
        .hamburger-inner:before {
            background: $white !important;
        }
    }
}
