// Sidebar menu dark color scheme

.app-sidebar {
  a {
    color: white;
  }
  &--dark {
    .sidebar-navigation {

      .sidebar-header {
        color: white;
      }

      ul {
        li {
          & > a {
            color: white;

            .sidebar-icon {
              & > * {
                color: $white;
                opacity: .3;
              }
            }
          }

          ul {
            li {
              a {
                &:after {
                  background: rgba($black, .2);
                }
              }
            }
          }
        }

        li > a:hover,
        li > a.active,
        li.active > a,
        li.submenu-open > a {
          color: rgba($white, 1);
          background: rgba($black, .15);

          .sidebar-icon-indicator {
            opacity: .7;
          }

          .sidebar-icon {
            & > * {
              opacity: .6;
            }
          }
        }
      }
    }
  }
}
