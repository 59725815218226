// Disable animation if transitions are disabled
@if $enable-transitions {
  @keyframes progress-bar-stripes {
    from { background-position: $progress-height 0; }
    to { background-position: 0 0; }
  }
}

.progress {
  display: flex;
  height: $progress-height;
  overflow: hidden; // force rounded corners by cropping it
  @include font-size($progress-font-size);
  background-color: $progress-bg;
  @include border-radius($progress-border-radius);
  @include box-shadow($progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: $progress-bar-color;
  text-align: center;
  white-space: nowrap;
  background-color: $progress-bar-bg;
  @include transition($progress-bar-transition);
}

.progress-bar-striped {
  @include gradient-striped();
  background-size: $progress-height $progress-height;
}

@if $enable-transitions {
  .progress-bar-animated {
    animation: progress-bar-stripes $progress-bar-animation-timing;

    @if $enable-prefers-reduced-motion-media-query {
      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }
  }
}

.progressbar {
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
}

.progressbar li {
    list-style-type: none;
    width: 33.33%;
    float: left;
    position: relative;
}

.progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 24px;
    height: 24px;
    display: block;
    color: #333;
    background: $meili-neutral;
    border-radius: 25px;
    margin: 2px auto 11px auto;
}

/*progressbar connectors*/
.progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: $meili-neutral;
    position: absolute;
    left: -50%;
    top: 12px;
    z-index: -1; /*put it behind the numbers*/
}

.progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
.progressbar li.active:before, .progressbar li.active:after {
    background: $meili-purple;
    color: $white
}
