// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    white-space: $btn-white-space;
    vertical-align: middle;
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $btn-font-size,
        $btn-line-height,
        $btn-border-radius
    );
    @include transition($btn-transition);

    @include hover() {
        color: $body-color;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        background-color: #f9fafb !important;
        color: #c2c5ce !important;
        border: #f9fafb !important;
        font-size: 14px !important;
        line-height: 28px !important;
        font-weight: 500 !important;

        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}
.btn-icon {
    width: 25px;
    height: 25px;
    text-align: center !important;
    padding: 0;
    border-radius: 50% !important;

    .btn-icon:active {
        box-shadow: 0 0 0 2em rgba(red, 0);
        color: 'green';
    }
}
.btn-save {
    width: 63px !important;
    height: 40px !important;
    border-radius: 8px !important;
    border: none !important;
    color: white !important;
    background: var(--primary-500, #670d95);
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    &:hover {
        background: #ba4ffa;
    }
}

.btn-cancel {
    width: 79px !important;
    height: 40px !important;
    border-radius: 8px !important;
    border: 1.5px solid var(--secondary-grey-blue-200, #dadbea);
    background: #fff;
    box-shadow: 0px 2px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.font-14 {
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
}
.font-16 {
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.1px;
}

.btn-circle {
    width: 45px;
    height: 45px;
    text-align: center;
    padding: 0;
    border-radius: 50% !important;
}

.btn-circle svg {
    top: 3px;
    position: relative;
}

.btn-circle-sm {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 0.9rem;
}

.btn-circle-lg {
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 1.1rem;
}

.btn-circle-xl {
    width: 8.8rem;
    height: 8.8rem;
    background: $gray-700 !important;
    span {
        font-size: 7.3rem;
    }
    &:hover {
        background: $meili-bg-color !important;
    }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    text-decoration: $link-decoration;

    @include hover() {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        box-shadow: none;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
    @include button-size(
        $btn-padding-y-lg,
        $btn-padding-x-lg,
        $btn-font-size-lg,
        $btn-line-height-lg,
        $btn-border-radius-lg
    );
}

.btn-sm {
    @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $btn-font-size-sm,
        $btn-line-height-sm,
        $btn-border-radius-sm
    );
}

//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
    &.btn-block {
        width: 100%;
    }
}

// login page classes

.size-14 {
    /* Body 3 */
    font-family: 'Poppins';
    font-size: 14px !important;
    font-style: normal;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
}

.size-12 {
    /* Body 3 */
    font-family: 'Poppins';
    font-size: 12px !important;
    font-style: normal;
    line-height: 18px;
    /* 142.857% */
    letter-spacing: 0.1px;
}
.weight-400 {
    font-weight: 400 !important;
}
.weight-500 {
    font-weight: 500 !important;
}

.grey-500 {
    color: #656d7e !important;
}
.grey-800 {
    color: #2f333b !important;
}
.grey-600 {
    color: #4e5462 !important;
}
.grey-700 {
    color: #3d414c !important;
}
.grey-900 {
    color: #24272d !important;
}
.grey-400 {
    color: #9da3b0 !important;
}

.pt-32 {
    padding-top: 32px !important;
}
.pb-32 {
    padding-bottom: 32px !important;
}
