// Variables

// General

$sidebar-width:             260px;
$app-sidebar-transition:    cubic-bezier(0.685, 0.0473, 0.346, 1);
// Dark color scheme

$sidebar-bg:                linear-gradient(180deg, rgba(103,13,149,1) 0%, rgba(145,59,196,1) 100%);
$sidebar-shadow:            $box-shadow-layout-right;
$sidebar-content-bg:        transparent;
