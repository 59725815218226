.app-page-title {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 0;
    z-index: 1;
    width: 78%;
    position: relative;
    height: 5%;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    transition: $transition-base;
    @media screen and (min-width: 1600px) {
          width: 78.6%;
      }
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        margin-left: 40px;
        height: 190px;
        top: 0;
        z-index: 0;
        position: relative;
    }

    &--heading {
        display: flex;

        @include media-breakpoint-down(md) {
            text-align: center;
            // margin-left: 50px;
        }

        h1 {
            font-size: $display4-size / 1.1;
            font-weight: 700;
            margin: 0;
            padding: 1rem;
        }
    }

    &--description {
        // margin: ($spacer / 2) 0 0;
        font-size: $font-size-base * 1.7;
        opacity: 0.6;
        font-weight: normal;
    }

    &--team {
        // margin: ($spacer / 2) 0 0;
        font-size: $font-size-base * 1.7;
        font-weight: bold;
    }
}

.collapse-title {
    width: 50%;

    @include media-breakpoint-down(md) {
        width: 50%;
    }
}


