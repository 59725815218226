.bg-purple {
    background: $sidebar-bg !important;
}


.app-sidebar {
    flex-direction: column;
    display: flex;
    height: 100%;
    z-index: 1015;
    position: relative;
    &--content {
        overflow-y: auto;
        overflow-x: visible;
        height: 100%;
        font-weight: 900;
        color: white;
    }
}
.org-app-sidebar {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s $app-sidebar-transition;
    z-index: 1015;
    color: white;
    max-height: 100vh;
    overflow: hidden;
    position: relative;
    &--content {
        overflow-y: hidden;
        overflow-x: hidden;
        height: 100%;
        font-weight: 900;
        color: white;
        
    }
}

.app-sidebar-customValue {
    flex-direction: column;
    display: flex;
    height: 100%;
    transition: all 0.5s $app-sidebar-transition;
    z-index: 1050;
    background: green;
    position: relative;
    &--content {
        overflow-y: auto;
        overflow-x: visible;
        height: 100%;
        font-weight: 900;
        color: white;
    }
}

// Fixed sidebar

.app-sidebar-fixed {
    .app-sidebar {
        display: none;
        position: fixed;
        left: 0;
    }
}

.app-sidebar-overlay {
    width: 0;
    height: 0;
    opacity: 1;
    transition: opacity 0.2s;
    position: fixed;
    left: 5px;
    top: 5px;
    right: 0;
    bottom: 0;
   

    @include media-breakpoint-down(lg) {
        &.is-active {
            opacity: 1;
            visibility: visible;
            width: 100%;
            height: 100%;
        }
    }
}

.app-sidebar-overlay a {
    color: white;
}

.org-app-sidebar-overlay a {
    color: white;
}

// Responsive

@include media-breakpoint-down(sm) {
    // .app-sidebar {
    //     position: fixed !important;
    //     left: -$sidebar-width !important;
    // }
.org-app-sidebar{
    position: fixed !important;
    left: -280px !important;

}

    .app-sidebar-mobile-open {
        .app-sidebar {
            left: 0 !important;
            box-shadow: $sidebar-shadow;
        }
    }
}

// Modifiers

@import 'modifiers/collapsed';

// Themes

@import 'themes/dark';
@import 'themes/light';
